import { TeacherAvailabilitySlotFragment } from "../gql/graphql";
import SvgTime from "../UI/Svgs/SvgTime";
import { formatTimeInTimezone } from "../TimeUtils/formatTimeInTimezone.ts";
import { XMarkIcon } from "@heroicons/react/16/solid";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
} from "@headlessui/react";
import { useControlCalendarCardModal } from "./useControlCalendarModal.ts";
import { WhiteLoadingIndicator } from "../UI/Loading/WhiteLoadingIndicator.tsx";

type Props = {
  slot: TeacherAvailabilitySlotFragment;
  handleDeleteSingleSlot: () => void;
  handleDeleteRecurringSlot: () => void;
  loadingDeleteSlot: boolean;
  loadingRecurringDeleteSlot: boolean;
};

export function AvailabilitySlotCard({
  slot,
  handleDeleteSingleSlot,
  handleDeleteRecurringSlot,
  loadingDeleteSlot,
  loadingRecurringDeleteSlot,
}: Props) {
  const { showModal, onClose, onOpen } = useControlCalendarCardModal(slot.id);

  function formatToCustomString(date: Date) {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${month}-${day}-${year}/${hours}:${minutes}`;
  }

  return (
    <>
      <div className=" cursor-default relative rounded-xl md:w-full bg-white w-[95%] hover:scale-105 transition-all ease-in-out flex justify-self-center border-t-4 border-primary">
        <button className="absolute w-4 h-4 right-1 top-1" onClick={onOpen}>
          <XMarkIcon className="w-4 h-4 fill-red-600" />
        </button>
        <div
          className="w-full flex md:gap-1 gap-3 flex-col items-start tracking-wide text-center shadow-lg py-2 px-2 rounded-xl"
          role="button"
          tabIndex={0}
        >
          <h2 className="pl-1 text-sm font-bold tracking-wider">
            Availability
          </h2>
          <div className="flex gap-2 text-xs">
            <div className="flex items-center gap-2">
              <SvgTime className="w-4 h-4 flex-shrink-0" />
              <p className="text-left">
                {formatTimeInTimezone(slot.startDateTime, slot.slotTimezone)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Transition
        show={showModal}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog
          open={showModal}
          onClose={onClose}
          className="fixed inset-0 overflow-y-auto data-[closed]:opacity-0"
          transition
          unmount={false}
        >
          <DialogBackdrop className="fixed inset-0 bg-gray-500/40" transition />
          <div className="flex items-center justify-center min-h-screen">
            <DialogPanel
              transition
              className=" relative py-6 z-10 px-10 gap-4 text-justify w-[80%] md:w-[22%] md:h-[40%] h-[45%] shadow-lg bg-white flex flex-col rounded-2xl border-t-8 border-primary"
            >
              <button className="absolute top-2 right-2" onClick={onClose}>
                <XMarkIcon className="w-7 h-7 fill-red-600" />
              </button>
              <p className="w-full text-center font-bold tracking-widest text-xl">
                DELETE SLOT
              </p>
              <p className="text-start">
                {!slot.recurring ? (
                  "Are you sure you want to delete this slot?"
                ) : (
                  <>
                    Delete single slot or recurring <br />
                    from: <br />
                    <b>
                      {formatToCustomString(new Date(slot.startDateTime))}
                    </b>{" "}
                    <br />
                    to: <br />
                    <b>{formatToCustomString(new Date(slot.endDateTime))}</b>
                  </>
                )}
              </p>
              {slot.recurring ? (
                <div className="flex justify-center items-center gap-4 px-10">
                  <button
                    disabled={loadingDeleteSlot}
                    onClick={handleDeleteSingleSlot}
                    className={
                      "hover:cursor-pointer text-sm hover:brightness-110 w-full justify-center bg-primary rounded-lg text-white px-4 py-1 flex items-center space-x-2 whitespace-nowrap text-center"
                    }
                  >
                    {loadingDeleteSlot ? (
                      <WhiteLoadingIndicator />
                    ) : (
                      "DELETE SINGLE"
                    )}
                  </button>
                  <button
                    disabled={loadingDeleteSlot}
                    onClick={handleDeleteRecurringSlot}
                    className={
                      "hover:cursor-pointer text-sm hover:brightness-110 bg-primary w-full justify-center rounded-lg text-white px-4 py-1 flex items-center space-x-2 whitespace-nowrap text-center"
                    }
                  >
                    {loadingRecurringDeleteSlot ? (
                      <WhiteLoadingIndicator />
                    ) : (
                      "DELETE RECURRING"
                    )}
                  </button>
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <button
                    disabled={loadingDeleteSlot}
                    onClick={handleDeleteSingleSlot}
                    className={
                      "hover:cursor-pointer hover:brightness-110 bg-primary w-full justify-center rounded-lg text-white px-4 py-1 flex text-sm items-center space-x-2 whitespace-nowrap text-center"
                    }
                  >
                    {loadingDeleteSlot ? <WhiteLoadingIndicator /> : "DELETE"}
                  </button>
                </div>
              )}
            </DialogPanel>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
