import { PlusIcon } from "@heroicons/react/16/solid";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export function AddSlotButton({ open, setOpen }: Props) {
  return (
    <button
      onClick={() => setOpen(!open)}
      className="group hover:scale-110 duration-500 shadow-2xl transition-all ease-in-out p-2 w-10 h-10 md:w-14 md:h-14 rounded-full bg-blue-800 fixed bottom-4 right-5 md:right-12"
    >
      <PlusIcon className="text-white" />
      <div className="group-hover:flex hidden justify-center items-center absolute w-fit py-1 px-2 bg-white rounded-lg shadow-xl -top-1 md:top-0 h-4 md:h-6 -translate-y-full -translate-x-1/2 ml-2 bottom-4 md:right-0 left-0 md:left-2">
        <p className="md:text-xs text-[0.5rem] whitespace-nowrap">
          Add availability slot
        </p>
      </div>
    </button>
  );
}
