import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

export const RequestClassRoomDeletionMutation = graphql(`
  mutation RequestClassRoomDeletion($classId: String!) {
    requestClassRoomDeletion(classId: $classId)
  }
`);

export function useRequestClassRoomDeletion() {
  const [requestClassRoomDeletionMutation, { loading }] = useMutation(
    RequestClassRoomDeletionMutation,
  );

  const requestClassRoomDeletion = useCallback(
    async (classId: string) => {
      await requestClassRoomDeletionMutation({
        variables: {
          classId,
        },
      });
    },
    [requestClassRoomDeletionMutation],
  );
  return {
    requestClassRoomDeletion,
    loading,
  };
}
