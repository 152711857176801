/* eslint-disable */
import * as types from "./graphql.js";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
  "\n  query getCurrentLoggedInTeacher {\n    getLoggedTeacher {\n      ...Teacher\n    }\n  }\n":
    types.GetCurrentLoggedInTeacherDocument,
  "\n  fragment Teacher on Franchisee {\n    email\n    familyName\n    givenName\n    id\n    phoneNumber {\n      countryCode\n      number\n    }\n    shiftBaseId\n  }\n":
    types.TeacherFragmentDoc,
  "\n  fragment ClassRoomFragment on ClassRoom {\n    id\n    topic {\n      name\n      id\n      pdfUrl\n    }\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    participants {\n      ...ClassRoomParticipant\n    }\n    feedbackReportUrl\n  }\n":
    types.ClassRoomFragmentFragmentDoc,
  "\n  fragment ClassRoomParticipant on ClassRoomParticipant {\n    id\n    givenName\n    familyName\n    executedClassRoomCount\n    speakingLevel\n  }\n":
    types.ClassRoomParticipantFragmentDoc,
  "\n  query getClassRoomById($classRoomId: String!) {\n    getClassRoomById(classRoomId: $classRoomId) {\n      ...ClassRoomFragment\n    }\n  }\n":
    types.GetClassRoomByIdDocument,
  "\n  fragment ClassRoomV2 on ClassRoomV2 {\n    id\n    startedAtUtc\n    endedAtUtc\n    participantsCount\n    speakingLevel\n    topic {\n      id\n      level\n      name\n      pdfUrl\n    }\n    type\n    meetingUri\n    capacity\n    feedbackUri\n    teacherId\n    progressCheckTopic {\n      level\n      pdfUri\n    }\n    feedbackReportUri\n    classRoomTimezone\n  }\n":
    types.ClassRoomV2FragmentDoc,
  "\n  mutation CreateOneHourAvailabilitySlot(\n    $input: CreateOneHourAvailabilitySlotInput!\n  ) {\n    createOneHourAvailabilitySlot(input: $input) {\n      endDateTime\n      id\n      startDateTime\n    }\n  }\n":
    types.CreateOneHourAvailabilitySlotDocument,
  "\n  mutation Mutation($input: CreateRecurringAvailabilitySlotInput!) {\n    createRecurringAvailabilitySlot(input: $input) {\n      endDateTime\n      id\n      startDateTime\n    }\n  }\n":
    types.MutationDocument,
  "\n  query getCurrentLoggedInTeacherAvailability(\n    $maxDateString: String!\n    $minDateString: String!\n  ) {\n    getCurrentLoggedInTeacherAvailability(\n      maxDateString: $maxDateString\n      minDateString: $minDateString\n    ) {\n      ...TeacherAvailabilitySlot\n    }\n  }\n":
    types.GetCurrentLoggedInTeacherAvailabilityDocument,
  "\n  fragment TeacherAvailabilitySlot on TeacherAvailabilitySlot {\n    endDateTime\n    id\n    recurring\n    slotTimezone\n    startDateTime\n  }\n":
    types.TeacherAvailabilitySlotFragmentDoc,
  "\n  query getClassRoomsByTeacherAndDateRange(\n    $endUtc: String!\n    $startUtc: String!\n  ) {\n    getClassRoomsByTeacherAndDateRange(endUtc: $endUtc, startUtc: $startUtc) {\n      ...ClassRoomV2\n    }\n  }\n":
    types.GetClassRoomsByTeacherAndDateRangeDocument,
  "\n  mutation DeleteSequenceAvailabilitySlots($occurrenceId: String!) {\n    deleteSequenceAvailabilitySlots(occurrenceId: $occurrenceId)\n  }\n":
    types.DeleteSequenceAvailabilitySlotsDocument,
  "\n  mutation deleteAvailabilitySlot($occurrenceId: String!) {\n    deleteAvailabilitySlot(occurrenceId: $occurrenceId)\n  }\n":
    types.DeleteAvailabilitySlotDocument,
  "\n  mutation RequestClassRoomDeletion($classId: String!) {\n    requestClassRoomDeletion(classId: $classId)\n  }\n":
    types.RequestClassRoomDeletionDocument,
  "\n  fragment LessonFeedbackReportFragment on UserLessonFeedbackReport {\n    avgParticipationScore\n    avgTargetLanguageScore\n    notes {\n      classRoomStartDateUtc\n      classRoomTopicName\n      note\n    }\n    hasAtLeastOneFeedback\n    userGivenName\n    userFamilyName\n  }\n":
    types.LessonFeedbackReportFragmentFragmentDoc,
  "\n  mutation upsertLessonFeedback(\n    $classRoomId: String!\n    $lessonFeedbacks: [UpsertUserLessonFeedbackInput!]!\n  ) {\n    upsertLessonFeedback(\n      classRoomId: $classRoomId\n      lessonFeedback: $lessonFeedbacks\n    )\n  }\n":
    types.UpsertLessonFeedbackDocument,
  "\n  query getLessonFeedbackReportByUser($userId: String!) {\n    getLessonFeedbackReportByUser(userId: $userId) {\n      ...LessonFeedbackReportFragment\n    }\n  }\n":
    types.GetLessonFeedbackReportByUserDocument,
  "\n  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {\n    id\n    startedAtUtc\n    speakingLevel\n    feedbackReportUrl\n  }\n":
    types.ClassRoomWithoutParticipantFragmentDoc,
  "\n  fragment ProgressCheckResult on ProgressCheckResultDto {\n    accuracy\n    recommendation\n    vocabularyRange\n    fluency\n    comprehension\n    notes\n    userId\n    progressCheckId\n    progressCheckStartedAtUtc\n    progressCheckLevel\n    passed\n    noShow\n  }\n":
    types.ProgressCheckResultFragmentDoc,
  "\n  fragment ProgressCheckUser on ProgressCheckUserDto {\n    id\n    givenName\n    familyName\n    alreadyGivenFeedback\n    canImproveSpeakingLevel\n  }\n":
    types.ProgressCheckUserFragmentDoc,
  "\n  mutation giveProgressCheckFeedback(\n    $input: [GiveProgressCheckFeedbackInput!]!\n  ) {\n    giveProgressCheckFeedback(input: $input)\n  }\n":
    types.GiveProgressCheckFeedbackDocument,
  "\n  query getProgressCheckResultByUserId($userId: String!) {\n    getProgressCheckResultByUserId(userId: $userId) {\n      ...ProgressCheckResult\n    }\n  }\n":
    types.GetProgressCheckResultByUserIdDocument,
  "\n  query getProgressCheckUserList($progressCheckId: String!) {\n    getProgressCheckUserList(progressCheckId: $progressCheckId) {\n      progressCheck {\n        ...ClassRoomWithoutParticipant\n      }\n      participants {\n        ...ProgressCheckUser\n      }\n    }\n  }\n":
    types.GetProgressCheckUserListDocument,
  "\n  fragment WorkedHour on WorkedHour {\n    id\n    startDateUtc\n    endDateUtc\n    hourlyWageInCents\n  }\n":
    types.WorkedHourFragmentDoc,
  "\n  fragment WorkedHoursByMonthItem on WorkedHoursByMonthItem {\n    totalTaxableAmountInCents\n    startOfMonthDate\n    endOfMonthDate\n  }\n":
    types.WorkedHoursByMonthItemFragmentDoc,
  "\n  query myWorkedHoursByMonth(\n    $clientTimezone: String!\n    $startDateUtc: String!\n    $endDateUtc: String!\n  ) {\n    myWorkedHoursByMonth(\n      clientTimezone: $clientTimezone\n      startDateUtc: $startDateUtc\n      endDateUtc: $endDateUtc\n    ) {\n      ...WorkedHoursByMonthItem\n    }\n  }\n":
    types.MyWorkedHoursByMonthDocument,
  "\n  query getMyWorkedHoursOfAMonth(\n    $clientTimezone: String!\n    $monthStartDateTimeUtc: String!\n  ) {\n    getMyWorkedHoursOfAMonth(\n      clientTimezone: $clientTimezone\n      monthStartDateTimeUtc: $monthStartDateTimeUtc\n    ) {\n      ...WorkedHour\n    }\n  }\n":
    types.GetMyWorkedHoursOfAMonthDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getCurrentLoggedInTeacher {\n    getLoggedTeacher {\n      ...Teacher\n    }\n  }\n",
): (typeof documents)["\n  query getCurrentLoggedInTeacher {\n    getLoggedTeacher {\n      ...Teacher\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment Teacher on Franchisee {\n    email\n    familyName\n    givenName\n    id\n    phoneNumber {\n      countryCode\n      number\n    }\n    shiftBaseId\n  }\n",
): (typeof documents)["\n  fragment Teacher on Franchisee {\n    email\n    familyName\n    givenName\n    id\n    phoneNumber {\n      countryCode\n      number\n    }\n    shiftBaseId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomFragment on ClassRoom {\n    id\n    topic {\n      name\n      id\n      pdfUrl\n    }\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    participants {\n      ...ClassRoomParticipant\n    }\n    feedbackReportUrl\n  }\n",
): (typeof documents)["\n  fragment ClassRoomFragment on ClassRoom {\n    id\n    topic {\n      name\n      id\n      pdfUrl\n    }\n    speakingLevel\n    startedAtUtc\n    endedAtUtc\n    participants {\n      ...ClassRoomParticipant\n    }\n    feedbackReportUrl\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomParticipant on ClassRoomParticipant {\n    id\n    givenName\n    familyName\n    executedClassRoomCount\n    speakingLevel\n  }\n",
): (typeof documents)["\n  fragment ClassRoomParticipant on ClassRoomParticipant {\n    id\n    givenName\n    familyName\n    executedClassRoomCount\n    speakingLevel\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getClassRoomById($classRoomId: String!) {\n    getClassRoomById(classRoomId: $classRoomId) {\n      ...ClassRoomFragment\n    }\n  }\n",
): (typeof documents)["\n  query getClassRoomById($classRoomId: String!) {\n    getClassRoomById(classRoomId: $classRoomId) {\n      ...ClassRoomFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomV2 on ClassRoomV2 {\n    id\n    startedAtUtc\n    endedAtUtc\n    participantsCount\n    speakingLevel\n    topic {\n      id\n      level\n      name\n      pdfUrl\n    }\n    type\n    meetingUri\n    capacity\n    feedbackUri\n    teacherId\n    progressCheckTopic {\n      level\n      pdfUri\n    }\n    feedbackReportUri\n    classRoomTimezone\n  }\n",
): (typeof documents)["\n  fragment ClassRoomV2 on ClassRoomV2 {\n    id\n    startedAtUtc\n    endedAtUtc\n    participantsCount\n    speakingLevel\n    topic {\n      id\n      level\n      name\n      pdfUrl\n    }\n    type\n    meetingUri\n    capacity\n    feedbackUri\n    teacherId\n    progressCheckTopic {\n      level\n      pdfUri\n    }\n    feedbackReportUri\n    classRoomTimezone\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation CreateOneHourAvailabilitySlot(\n    $input: CreateOneHourAvailabilitySlotInput!\n  ) {\n    createOneHourAvailabilitySlot(input: $input) {\n      endDateTime\n      id\n      startDateTime\n    }\n  }\n",
): (typeof documents)["\n  mutation CreateOneHourAvailabilitySlot(\n    $input: CreateOneHourAvailabilitySlotInput!\n  ) {\n    createOneHourAvailabilitySlot(input: $input) {\n      endDateTime\n      id\n      startDateTime\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation Mutation($input: CreateRecurringAvailabilitySlotInput!) {\n    createRecurringAvailabilitySlot(input: $input) {\n      endDateTime\n      id\n      startDateTime\n    }\n  }\n",
): (typeof documents)["\n  mutation Mutation($input: CreateRecurringAvailabilitySlotInput!) {\n    createRecurringAvailabilitySlot(input: $input) {\n      endDateTime\n      id\n      startDateTime\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getCurrentLoggedInTeacherAvailability(\n    $maxDateString: String!\n    $minDateString: String!\n  ) {\n    getCurrentLoggedInTeacherAvailability(\n      maxDateString: $maxDateString\n      minDateString: $minDateString\n    ) {\n      ...TeacherAvailabilitySlot\n    }\n  }\n",
): (typeof documents)["\n  query getCurrentLoggedInTeacherAvailability(\n    $maxDateString: String!\n    $minDateString: String!\n  ) {\n    getCurrentLoggedInTeacherAvailability(\n      maxDateString: $maxDateString\n      minDateString: $minDateString\n    ) {\n      ...TeacherAvailabilitySlot\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment TeacherAvailabilitySlot on TeacherAvailabilitySlot {\n    endDateTime\n    id\n    recurring\n    slotTimezone\n    startDateTime\n  }\n",
): (typeof documents)["\n  fragment TeacherAvailabilitySlot on TeacherAvailabilitySlot {\n    endDateTime\n    id\n    recurring\n    slotTimezone\n    startDateTime\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getClassRoomsByTeacherAndDateRange(\n    $endUtc: String!\n    $startUtc: String!\n  ) {\n    getClassRoomsByTeacherAndDateRange(endUtc: $endUtc, startUtc: $startUtc) {\n      ...ClassRoomV2\n    }\n  }\n",
): (typeof documents)["\n  query getClassRoomsByTeacherAndDateRange(\n    $endUtc: String!\n    $startUtc: String!\n  ) {\n    getClassRoomsByTeacherAndDateRange(endUtc: $endUtc, startUtc: $startUtc) {\n      ...ClassRoomV2\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation DeleteSequenceAvailabilitySlots($occurrenceId: String!) {\n    deleteSequenceAvailabilitySlots(occurrenceId: $occurrenceId)\n  }\n",
): (typeof documents)["\n  mutation DeleteSequenceAvailabilitySlots($occurrenceId: String!) {\n    deleteSequenceAvailabilitySlots(occurrenceId: $occurrenceId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation deleteAvailabilitySlot($occurrenceId: String!) {\n    deleteAvailabilitySlot(occurrenceId: $occurrenceId)\n  }\n",
): (typeof documents)["\n  mutation deleteAvailabilitySlot($occurrenceId: String!) {\n    deleteAvailabilitySlot(occurrenceId: $occurrenceId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation RequestClassRoomDeletion($classId: String!) {\n    requestClassRoomDeletion(classId: $classId)\n  }\n",
): (typeof documents)["\n  mutation RequestClassRoomDeletion($classId: String!) {\n    requestClassRoomDeletion(classId: $classId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment LessonFeedbackReportFragment on UserLessonFeedbackReport {\n    avgParticipationScore\n    avgTargetLanguageScore\n    notes {\n      classRoomStartDateUtc\n      classRoomTopicName\n      note\n    }\n    hasAtLeastOneFeedback\n    userGivenName\n    userFamilyName\n  }\n",
): (typeof documents)["\n  fragment LessonFeedbackReportFragment on UserLessonFeedbackReport {\n    avgParticipationScore\n    avgTargetLanguageScore\n    notes {\n      classRoomStartDateUtc\n      classRoomTopicName\n      note\n    }\n    hasAtLeastOneFeedback\n    userGivenName\n    userFamilyName\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation upsertLessonFeedback(\n    $classRoomId: String!\n    $lessonFeedbacks: [UpsertUserLessonFeedbackInput!]!\n  ) {\n    upsertLessonFeedback(\n      classRoomId: $classRoomId\n      lessonFeedback: $lessonFeedbacks\n    )\n  }\n",
): (typeof documents)["\n  mutation upsertLessonFeedback(\n    $classRoomId: String!\n    $lessonFeedbacks: [UpsertUserLessonFeedbackInput!]!\n  ) {\n    upsertLessonFeedback(\n      classRoomId: $classRoomId\n      lessonFeedback: $lessonFeedbacks\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getLessonFeedbackReportByUser($userId: String!) {\n    getLessonFeedbackReportByUser(userId: $userId) {\n      ...LessonFeedbackReportFragment\n    }\n  }\n",
): (typeof documents)["\n  query getLessonFeedbackReportByUser($userId: String!) {\n    getLessonFeedbackReportByUser(userId: $userId) {\n      ...LessonFeedbackReportFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {\n    id\n    startedAtUtc\n    speakingLevel\n    feedbackReportUrl\n  }\n",
): (typeof documents)["\n  fragment ClassRoomWithoutParticipant on ClassRoomWithoutParticipantDto {\n    id\n    startedAtUtc\n    speakingLevel\n    feedbackReportUrl\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ProgressCheckResult on ProgressCheckResultDto {\n    accuracy\n    recommendation\n    vocabularyRange\n    fluency\n    comprehension\n    notes\n    userId\n    progressCheckId\n    progressCheckStartedAtUtc\n    progressCheckLevel\n    passed\n    noShow\n  }\n",
): (typeof documents)["\n  fragment ProgressCheckResult on ProgressCheckResultDto {\n    accuracy\n    recommendation\n    vocabularyRange\n    fluency\n    comprehension\n    notes\n    userId\n    progressCheckId\n    progressCheckStartedAtUtc\n    progressCheckLevel\n    passed\n    noShow\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ProgressCheckUser on ProgressCheckUserDto {\n    id\n    givenName\n    familyName\n    alreadyGivenFeedback\n    canImproveSpeakingLevel\n  }\n",
): (typeof documents)["\n  fragment ProgressCheckUser on ProgressCheckUserDto {\n    id\n    givenName\n    familyName\n    alreadyGivenFeedback\n    canImproveSpeakingLevel\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation giveProgressCheckFeedback(\n    $input: [GiveProgressCheckFeedbackInput!]!\n  ) {\n    giveProgressCheckFeedback(input: $input)\n  }\n",
): (typeof documents)["\n  mutation giveProgressCheckFeedback(\n    $input: [GiveProgressCheckFeedbackInput!]!\n  ) {\n    giveProgressCheckFeedback(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getProgressCheckResultByUserId($userId: String!) {\n    getProgressCheckResultByUserId(userId: $userId) {\n      ...ProgressCheckResult\n    }\n  }\n",
): (typeof documents)["\n  query getProgressCheckResultByUserId($userId: String!) {\n    getProgressCheckResultByUserId(userId: $userId) {\n      ...ProgressCheckResult\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getProgressCheckUserList($progressCheckId: String!) {\n    getProgressCheckUserList(progressCheckId: $progressCheckId) {\n      progressCheck {\n        ...ClassRoomWithoutParticipant\n      }\n      participants {\n        ...ProgressCheckUser\n      }\n    }\n  }\n",
): (typeof documents)["\n  query getProgressCheckUserList($progressCheckId: String!) {\n    getProgressCheckUserList(progressCheckId: $progressCheckId) {\n      progressCheck {\n        ...ClassRoomWithoutParticipant\n      }\n      participants {\n        ...ProgressCheckUser\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment WorkedHour on WorkedHour {\n    id\n    startDateUtc\n    endDateUtc\n    hourlyWageInCents\n  }\n",
): (typeof documents)["\n  fragment WorkedHour on WorkedHour {\n    id\n    startDateUtc\n    endDateUtc\n    hourlyWageInCents\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment WorkedHoursByMonthItem on WorkedHoursByMonthItem {\n    totalTaxableAmountInCents\n    startOfMonthDate\n    endOfMonthDate\n  }\n",
): (typeof documents)["\n  fragment WorkedHoursByMonthItem on WorkedHoursByMonthItem {\n    totalTaxableAmountInCents\n    startOfMonthDate\n    endOfMonthDate\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query myWorkedHoursByMonth(\n    $clientTimezone: String!\n    $startDateUtc: String!\n    $endDateUtc: String!\n  ) {\n    myWorkedHoursByMonth(\n      clientTimezone: $clientTimezone\n      startDateUtc: $startDateUtc\n      endDateUtc: $endDateUtc\n    ) {\n      ...WorkedHoursByMonthItem\n    }\n  }\n",
): (typeof documents)["\n  query myWorkedHoursByMonth(\n    $clientTimezone: String!\n    $startDateUtc: String!\n    $endDateUtc: String!\n  ) {\n    myWorkedHoursByMonth(\n      clientTimezone: $clientTimezone\n      startDateUtc: $startDateUtc\n      endDateUtc: $endDateUtc\n    ) {\n      ...WorkedHoursByMonthItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query getMyWorkedHoursOfAMonth(\n    $clientTimezone: String!\n    $monthStartDateTimeUtc: String!\n  ) {\n    getMyWorkedHoursOfAMonth(\n      clientTimezone: $clientTimezone\n      monthStartDateTimeUtc: $monthStartDateTimeUtc\n    ) {\n      ...WorkedHour\n    }\n  }\n",
): (typeof documents)["\n  query getMyWorkedHoursOfAMonth(\n    $clientTimezone: String!\n    $monthStartDateTimeUtc: String!\n  ) {\n    getMyWorkedHoursOfAMonth(\n      clientTimezone: $clientTimezone\n      monthStartDateTimeUtc: $monthStartDateTimeUtc\n    ) {\n      ...WorkedHour\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
