import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  GiveLessonFeedbackScreen,
  GiveLessonFeedbackScreenPath,
} from "./LessonFeedback/GiveLessonFeedbackScreen.tsx";
import { ApolloProvider } from "@apollo/client";
import { useMemo } from "react";
import { useCreateApolloClient } from "./ApolloClient/useCreateApolloClient.ts";
import {
  LessonFeedbackReportUserListScreen,
  LessonFeedbackReportUserListScreenPath,
} from "./LessonFeedback/LessonFeedbackReportUserListScreen.tsx";
import {
  UserLessonFeedbackReportScreen,
  UserLessonFeedbackReportScreenPath,
} from "./LessonFeedback/UserLessonFeedbackReportScreen.tsx";
import {
  SentLessonFeedbackThankYouPage,
  SentLessonFeedbackThankYouPagePath,
} from "./LessonFeedback/SentLessonFeedbackThankYouPage.tsx";
import { TestScreen, TestScreenPath } from "./TestScreen.tsx";
import {
  GiveProgressCheckFeedbackScreen,
  GiveProgressCheckFeedbackScreenPath,
} from "./ProgressCheck/GiveProgressCheckFeedbackScreen.tsx";
import {
  GiveProgressCheckFeedbackThankYouScreen,
  GiveProgressCheckFeedbackThankYouScreenPath,
} from "./ProgressCheck/GiveProgressCheckFeedbackThankYouScreen.tsx";
import { LoginScreen, LoginScreenPath } from "./Auth/LoginScreen.tsx";
import { ProtectedRoute } from "./Auth/ProtectedRoute.tsx";
import { FranchiseeCalendarAvailabilityScreen } from "./Franchisee/FranchiseeAvailability/FranchiseeCalendarAvailabilityScreen.tsx";
import { FranchiseeCalendarAvailabilityScreenPath } from "./Franchisee/FranchiseeAvailability/FranchiseeCalendarAvailabilityScreenPath.ts";
import { AuthCtxProvider } from "./Auth/AuthCtxProvider.tsx";
import {
  ExecutedClassRoomsScreen,
  ExecutedClassRoomsScreenPath,
} from "./WorkedHours/Screens/ExecutedClassRoomsScreen.tsx";
import {
  MonthExecutedClassRoomScreen,
  MonthExecutedClassRoomScreenPath,
} from "./WorkedHours/Screens/MonthExecutedClassRoomScreen.tsx";
import { GiveAvailabilitiesScreenPath } from "./Franchisee/FranchiseeAvailability/GiveAvailabilitiesScreenPath.ts";
import { GiveAvailabilitiesScreen } from "./Franchisee/FranchiseeAvailability/GiveAvailabilitiesScreen.tsx";
function App() {
  const { createApolloClient } = useCreateApolloClient();
  const apolloClient = useMemo(
    () => createApolloClient(),
    [createApolloClient],
  );
  return (
    <ApolloProvider client={apolloClient}>
      <AuthCtxProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path={"/"}
              element={
                <Navigate to={FranchiseeCalendarAvailabilityScreenPath} />
              }
            />
            <Route path={LoginScreenPath} element={<LoginScreen />} />
            <Route
              path={FranchiseeCalendarAvailabilityScreenPath}
              element={
                <ProtectedRoute>
                  <FranchiseeCalendarAvailabilityScreen />
                </ProtectedRoute>
              }
            />

            <Route
              path={GiveLessonFeedbackScreenPath}
              element={<GiveLessonFeedbackScreen />}
            />
            <Route
              path={LessonFeedbackReportUserListScreenPath}
              element={<LessonFeedbackReportUserListScreen />}
            />
            <Route
              path={UserLessonFeedbackReportScreenPath}
              element={<UserLessonFeedbackReportScreen />}
            />
            <Route
              path={SentLessonFeedbackThankYouPagePath}
              element={<SentLessonFeedbackThankYouPage />}
            />
            <Route
              path={GiveProgressCheckFeedbackScreenPath}
              element={<GiveProgressCheckFeedbackScreen />}
            />
            <Route
              path={GiveProgressCheckFeedbackThankYouScreenPath}
              element={<GiveProgressCheckFeedbackThankYouScreen />}
            />
            <Route
              path={GiveAvailabilitiesScreenPath}
              element={
                <ProtectedRoute>
                  <GiveAvailabilitiesScreen />
                </ProtectedRoute>
              }
            />
            <Route path={TestScreenPath} element={<TestScreen />} />
            <Route
              path={ExecutedClassRoomsScreenPath}
              element={
                <ProtectedRoute>
                  <ExecutedClassRoomsScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={MonthExecutedClassRoomScreenPath}
              element={
                <ProtectedRoute>
                  <MonthExecutedClassRoomScreen />
                </ProtectedRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthCtxProvider>
    </ApolloProvider>
  );
}

export default App;
