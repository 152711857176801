import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

export const DeleteAvailabilitySlotMutation = graphql(`
  mutation deleteAvailabilitySlot($occurrenceId: String!) {
    deleteAvailabilitySlot(occurrenceId: $occurrenceId)
  }
`);

export function useDeleteAvailabilitySlot() {
  const [deleteAvailabilitySlotMutation, { loading }] = useMutation(
    DeleteAvailabilitySlotMutation,
  );

  const deleteAvailabilitySlot = useCallback(
    async (occurrenceId: string) => {
      await deleteAvailabilitySlotMutation({
        variables: {
          occurrenceId,
        },
      });
    },
    [deleteAvailabilitySlotMutation],
  );

  return {
    deleteAvailabilitySlot,
    loading,
  };
}
