import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
} from "@headlessui/react";
import { PrimaryButton } from "./PrimaryButton.tsx";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/16/solid";

export function DeleteClassroomModal({
  openDeleteClassroom,
  setOpenDeleteClassroom,
  handleDeleteRequest,
  loading,
}: {
  openDeleteClassroom: boolean;
  setOpenDeleteClassroom: (openDeleteClassroom: boolean) => void;
  handleDeleteRequest: () => void;
  loading: boolean;
}) {
  return (
    <Transition
      show={openDeleteClassroom}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog
        onClose={() => setOpenDeleteClassroom(!openDeleteClassroom)}
        className={``}
      >
        <DialogBackdrop className="fixed inset-0 z-40 " transition>
          <div className="flex items-center justify-center min-h-screen">
            <DialogPanel
              transition
              className="relative gap-3 justify-center items-center py-10 md:py-8 z-30 px-10 w-[80%] md:w-[35%] md:h-[40%] h-[45%] shadow-lg bg-white flex flex-col rounded-2xl border-t-8 border-red-500"
            >
              <XMarkIcon
                className="w-7 h-7 absolute top-3 right-3 fill-red-600 cursor-pointer"
                onClick={() => setOpenDeleteClassroom(!openDeleteClassroom)}
              />
              <div className="w-full relative flex flex-col items-center gap-4 py-12">
                <ExclamationTriangleIcon className="w-10 h-10 fill-red-600" />
                <h3 className="text-lg font-bold tracking-wide text-center">
                  DO YOU WANT TO CONFIRM?
                </h3>
                <p className="text-sm font-semibold text-justify">
                  By clicking the delete button below, a message will be sent to
                  the teacher coordinator, who will handle your request as soon
                  as possible.
                </p>
                <PrimaryButton
                  className="bg-red-500"
                  label="DELETE"
                  loading={loading}
                  onClick={handleDeleteRequest}
                />
              </div>
            </DialogPanel>
          </div>
        </DialogBackdrop>
      </Dialog>
    </Transition>
  );
}
