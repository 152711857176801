import dayjs, { Dayjs } from "dayjs";
import { ReactNode, useState } from "react";
import { DisabledEyeIcon } from "../UI/DisabledEyeIcon";
import {
  CalendarDatePicker,
  CalendarItemMap,
  DesktopCalendarComponent,
  Interval,
  MobileCalendarComponent,
} from "@normasteaching/norma-ui";

function CalendarDisabledComponent({ desc }: { desc: string }) {
  return (
    <div
      className={
        "flex flex-col items-center h-full flex-1 justify-center space-y-4 p-3"
      }
    >
      <DisabledEyeIcon />
      <div className={"space-y-2"}>
        <p className={"text-center max-w-md"}>{desc}</p>
      </div>
    </div>
  );
}
function CalendarDisabledAfterThreeWeeksInFuture() {
  return <CalendarDisabledComponent desc="desc" />;
}
export function WeeklyCalendar<T>({
  interval,
  items,
  loading,
  onChangeInterval,
  itemRenderer,
  emptyDayComponent,
  canGoToPrevWeek,
  disabled,
}: {
  interval: Interval;
  items: CalendarItemMap<T>;
  loading: boolean;
  onChangeInterval: (interval: Interval) => void;
  itemRenderer: (item: T) => ReactNode;
  emptyDayComponent: ReactNode;
  canGoToPrevWeek: boolean;
  disabled?: {
    isDisabled: boolean;
    reason: string;
  };
}) {
  const selectedDate = interval.fromDate.toDate();
  const [selectedDay, setSelectedDay] = useState<Dayjs>();

  const datePickerComponent: (renderProps: {
    close: () => void;
  }) => ReactNode = ({ close }) => (
    <CalendarDatePicker
      isSelectedDay={(d) => dayjs(selectedDate).isSame(d, "week")}
      selectedDate={selectedDate}
      onChange={(date) => {
        const d = dayjs(date);
        onChangeInterval(new Interval(d.startOf("week"), d.endOf("week")));
        setSelectedDay(d);
        close();
      }}
      canNavigateBack={() => true}
      isDisabled={() => false}
    />
  );
  return (
    <>
      <div className={"hidden md:flex flex-1 flex-col px-6 py-2"}>
        <DesktopCalendarComponent
          language="en"
          canGoToNextPeriod={true}
          canGoToPreviousPeriod={canGoToPrevWeek}
          emptyDayComponent={emptyDayComponent}
          previousPeriodLabel="Previous"
          nextPeriodLabel="Next"
          interval={interval}
          onChangeInterval={onChangeInterval}
          loading={loading}
          items={items}
          itemRenderer={itemRenderer}
          datePickerComponent={datePickerComponent}
          disabled={{
            disabled: false,
            disabledComponent: disabled?.isDisabled ? (
              <CalendarDisabledComponent desc={disabled.reason} />
            ) : (
              <CalendarDisabledAfterThreeWeeksInFuture />
            ),
          }}
        />
      </div>
      <div className={"flex flex-col md:hidden flex-1"}>
        <MobileCalendarComponent
          language="en"
          scrollToDay={selectedDay}
          items={items}
          interval={interval}
          itemRenderer={itemRenderer}
          onChangeInterval={onChangeInterval}
          loading={loading}
          emptyDayComponent={emptyDayComponent}
          datePickerComponent={datePickerComponent}
          disabled={{
            disabled: false,
            disabledComponent: disabled?.isDisabled ? (
              <CalendarDisabledComponent desc={disabled.reason} />
            ) : (
              <CalendarDisabledAfterThreeWeeksInFuture />
            ),
          }}
        />
      </div>
    </>
  );
}
