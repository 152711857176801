import { graphql } from "../gql";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";

export const DeleteAvailabilityRecurringSlotMutation = graphql(`
  mutation DeleteSequenceAvailabilitySlots($occurrenceId: String!) {
    deleteSequenceAvailabilitySlots(occurrenceId: $occurrenceId)
  }
`);

export function useDeleteSequenceAvailabilityRecurringSlot() {
  const [deleteAvailabilitySlotMutation, { loading }] = useMutation(
    DeleteAvailabilityRecurringSlotMutation,
  );

  const deleteRecurringAvailabilitySlots = useCallback(
    async (occurrenceId: string) => {
      await deleteAvailabilitySlotMutation({
        variables: {
          occurrenceId,
        },
      });
    },
    [deleteAvailabilitySlotMutation],
  );

  return {
    deleteRecurringAvailabilitySlots,
    loading,
  };
}
