import { AvailabilitySlotCard } from "./AvailabilitySlotCard";
import {
  CalendarSlotType,
  CalendarSlotWithType,
} from "./calendarSlotWithTypes";
import { ClassRoomSlotCard } from "./ClassRoomSlotCard";
import { useDeleteAvailabilitySlot } from "./useDeleteSingleAvailabilitySlot.ts";
import { useDeleteSequenceAvailabilityRecurringSlot } from "./useDeleteRecurringAvailabilitySlots.ts";
import { TeacherAvailabilitySlot } from "../gql/graphql.ts";
import { DeleteClassroomModal } from "../UI/DeleteClassroomModal.tsx";
import { useRequestClassRoomDeletion } from "./useRequestClassRoomDeletion.ts";
import { useState } from "react";

type Props = {
  slot: CalendarSlotWithType;
  refetchAvailabilitySlots: () => void;
};

export function SlotCards({ slot, refetchAvailabilitySlots }: Props) {
  const [openDeleteClassroom, setOpenDeleteClassroom] =
    useState<boolean>(false);
  const { deleteAvailabilitySlot, loading: loadingDeleteSlot } =
    useDeleteAvailabilitySlot();
  const { deleteRecurringAvailabilitySlots, loading: loadingDeleteRecurring } =
    useDeleteSequenceAvailabilityRecurringSlot();
  const { requestClassRoomDeletion, loading: loadingRequestClassRoomDeletion } =
    useRequestClassRoomDeletion();

  async function handleDeleteSingleSlot(slot: TeacherAvailabilitySlot) {
    await deleteAvailabilitySlot(slot.id);
    refetchAvailabilitySlots();
  }

  async function handleDeleteRequest(slotId: string) {
    await requestClassRoomDeletion(slotId);
    handleOpenDeleteClassroom();
    refetchAvailabilitySlots();
  }

  async function handleDeleteRecurringSlot(slot: TeacherAvailabilitySlot) {
    await deleteRecurringAvailabilitySlots(slot.id);
    refetchAvailabilitySlots();
  }

  const handleOpenDeleteClassroom = () => {
    setOpenDeleteClassroom(!openDeleteClassroom);
  };

  if (slot.type === CalendarSlotType.Availability) {
    return (
      <AvailabilitySlotCard
        handleDeleteRecurringSlot={() => handleDeleteRecurringSlot(slot.slot)}
        handleDeleteSingleSlot={() => handleDeleteSingleSlot(slot.slot)}
        loadingDeleteSlot={loadingDeleteSlot}
        loadingRecurringDeleteSlot={loadingDeleteRecurring}
        slot={slot.slot}
      />
    );
  } else if (slot.type === CalendarSlotType.ClassRoom) {
    return (
      <>
        <ClassRoomSlotCard
          handleOpenDeleteClassroom={handleOpenDeleteClassroom}
          slot={slot.slot}
        />
        <DeleteClassroomModal
          handleDeleteRequest={() => handleDeleteRequest(slot.slot.id)}
          openDeleteClassroom={openDeleteClassroom}
          setOpenDeleteClassroom={setOpenDeleteClassroom}
          loading={loadingRequestClassRoomDeletion}
        />
      </>
    );
  } else {
    return null;
  }
}
