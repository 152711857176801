import { ClassRoomSlotCardsProp } from "./ClassRoomSlotCardsProp";
import { ProgressCheckSlotCard } from "./ProgressCheckSlotCard";
import { SpeakingClassSlotCard } from "./SpeakingClassSlotCard";
import { ClassRoomType } from "../gql/graphql";

export function ClassRoomSlotCard({
  slot,
  handleOpenDeleteClassroom,
}: ClassRoomSlotCardsProp) {
  if (slot.type === ClassRoomType.Exam) {
    return (
      <ProgressCheckSlotCard
        handleOpenDeleteClassroom={handleOpenDeleteClassroom}
        slot={slot}
      />
    );
  } else {
    return (
      <SpeakingClassSlotCard
        handleOpenDeleteClassroom={handleOpenDeleteClassroom}
        slot={slot}
      />
    );
  }
}
